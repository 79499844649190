import { isObjectLike } from "lodash";
import { Form } from "@memberpoint/mp-components";

export function isLooseObject(
  value: unknown
): value is Record<string, unknown> {
  return isObjectLike(value);
}

export function isFormObject(form: unknown): form is Form {
  return isLooseObject(form) && "fields" in form && Array.isArray(form.fields);
}
