import {
  default as Axios,
  AxiosRequestConfig,
  AxiosResponse,
  AxiosError,
} from "axios";
import Authentication from "@/lib/Authentication";
import Config from "@/lib/Configuration";
let RefreshingPromise: null | Promise<string> = null;

const AppHttpResource = Axios.create({
  baseURL: Config.apiBaseUrl,
});

/**
 * An Axios Interceptor that that will add a bearer token to each request.
 * if a token refresh is in progress, then pause the request until the refresh has finished.
 *
 * @param config
 * @constructor
 */
function RefreshRequestInterceptor(
  config: AxiosRequestConfig
): AxiosRequestConfig | Promise<AxiosRequestConfig> {
  return new Promise((resolve) => {
    if (!config.headers) {
      config.headers = {};
    }

    config.headers.Authorization = "Bearer " + Authentication.getToken();
    config.headers["Content-Type"] = "application/json";

    /*
     * If the application is currently in the middle of refreshing a JWT token, pause
     * and run this request after the user has re-authenticated.
     */
    if (RefreshingPromise !== null) {
      RefreshingPromise.then(() => {
        resolve(config);
      });
    } else {
      resolve(config);
    }
  });
}

/**
 * An Axios Response Interceptor that will handle a refresh promise
 * @param err
 * @constructor
 */
function RefreshResponseInterceptor(err: AxiosError): Promise<AxiosResponse> {
  return new Promise((resolve, reject) => {
    if (
      typeof err === "object" &&
      err !== null &&
      Object.prototype.hasOwnProperty.call(err, "response") &&
      typeof err.response !== "undefined"
    ) {
      const response: AxiosResponse<unknown> = err.response;

      // If the error is a 401, then do this
      if (response.status === 401 && response.statusText !== "ECONNABORTED") {
        // If we have a response promise, then add this to the que.
        if (RefreshingPromise !== null) {
          RefreshingPromise.then(() => {
            AppHttpResource.request(err.config)
              .then((res: AxiosResponse) => {
                resolve(res);
              })
              .catch((err: AxiosResponse) => {
                reject(err);
              });
          }).catch((err) => {
            reject(err);
          });
        } else {
          // If we dont have a response promise, create one and add this to the que
          RefreshingPromise = Authentication.refreshToken();
          RefreshingPromise.then(() => {
            AppHttpResource.request(err.config)
              .then((res) => {
                resolve(res);
              })
              .catch((err) => {
                reject(err);
              });
          });
          RefreshingPromise.catch(() => {
            reject(err);
          });
          RefreshingPromise.finally(() => {
            RefreshingPromise = null;
          });
        }
      } else {
        reject(response);
      }
    } else {
      reject(err);
    }
  });
}

/*
 * Add the Bearer token to each request.
 */
AppHttpResource.interceptors.request.use(RefreshRequestInterceptor);
AppHttpResource.defaults.withCredentials = true;
AppHttpResource.interceptors.response.use(
  undefined,
  RefreshResponseInterceptor
);
export default AppHttpResource;
