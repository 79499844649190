import http from "@/lib/AppHttpResource";
import Configuration from "@/lib/Configuration";
import { AxiosResponse } from "axios";
import Program from "@/Classes/Program";
import Store from "@/lib/store";
import { isLooseObject, isFormObject } from "@/lib/utils";
import { Form } from "@memberpoint/mp-components";

export type ProgramSessionChecklistDetailsPayload = {
  isCompleted: boolean;
  completedBy: string | null;
  completedDate: string | null;
};

export class ProgramsManager {
  /**
   * Get programs, including sessions and attendance for marker.
   */
  getProgramsForCurrentUser(): Promise<Array<Program>> {
    return new Promise<Array<Program>>((resolve, reject) => {
      const url = `${Configuration.apiBaseUrl}/app/programs/marker`;
      http
        .get(url)
        .then((response) => {
          this.resolveResponseMeta(response);
          const data = response.data.data || [];

          if (data.length === 0) {
            return resolve([]); // Empty data, resolve with empty programs array
          }

          const programs: Program[] = [];

          data.forEach((row: unknown) => {
            if (isLooseObject(row)) {
              programs.push(new Program(row));
            }
          });

          resolve(programs);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * Get program by UUID.
   *
   * @param programUuid
   */
  getProgram(programUuid: string): Promise<Program> {
    return new Promise<Program>((resolve, reject) => {
      const url = `${Configuration.apiBaseUrl}/app/programs/marker/view/${programUuid}`;

      http
        .get(url)
        .then((response) => {
          this.resolveResponseMeta(response);
          const data = response.data.data || [];

          if (data.length !== 1) {
            return reject("INVALID_DATA");
          }

          resolve(new Program(data[0]));
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * Get program by PRC.
   *
   * @param PRC
   */
  getProgramByPRC(PRC: string): Promise<Program> {
    return new Promise<Program>((resolve, reject) => {
      const url = `${Configuration.apiBaseUrl}/app/programs/marker/view/prc/${PRC}`;

      http
        .get(url)
        .then((response) => {
          this.resolveResponseMeta(response);
          const data = response.data.data || [];

          if (data.length !== 1) {
            return reject("INVALID_DATA");
          }
          resolve(new Program(data[0]));
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * Get the checklist form for the program session.
   *
   * @param programUuid
   * @param sessionUuid
   */
  getProgramSessionChecklistForm(
    programUuid: string,
    sessionUuid: string
  ): Promise<Form> {
    return new Promise<Form>((resolve, reject) => {
      const url = `${Configuration.apiBaseUrl}/app/programs/marker/${programUuid}/sessions/${sessionUuid}/checklist/form`;

      http
        .get(url)
        .then((response) => {
          const form = response.data.form;

          if (!isFormObject(form)) {
            return reject("INVALID_DATA");
          }

          resolve(form as Form);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * Get the checklist details for the program session.
   *
   * @param programUuid
   * @param sessionUuid
   */
  getProgramSessionChecklistDetails(
    programUuid: string,
    sessionUuid: string
  ): Promise<ProgramSessionChecklistDetailsPayload> {
    return new Promise<ProgramSessionChecklistDetailsPayload>(
      (resolve, reject) => {
        const url = `${Configuration.apiBaseUrl}/app/programs/marker/${programUuid}/sessions/${sessionUuid}/checklist`;

        http
          .get(url)
          .then((response) => {
            const data = response.data.data;

            if (!Array.isArray(data) || data.length !== 1) {
              return reject("INVALID_DATA");
            }

            const singleData = data[0];

            const payload: ProgramSessionChecklistDetailsPayload = {
              isCompleted: true === singleData.attributes?.isCompleted,
              completedBy: null,
              completedDate: null,
            };

            if (isLooseObject(singleData.attributes)) {
              if ("string" === typeof singleData.attributes.completedBy) {
                payload.completedBy = singleData.attributes.completedBy;
              }

              if ("string" === typeof singleData.attributes.completedDate) {
                payload.completedDate = singleData.attributes.completedDate;
              }
            }

            resolve(payload);
          })
          .catch((error) => {
            reject(error);
          });
      }
    );
  }

  private resolveResponseMeta(response: AxiosResponse): void {
    if (
      Object.prototype.hasOwnProperty.call(response.data, "meta") &&
      Object.prototype.hasOwnProperty.call(response.data.meta, "guestType")
    ) {
      Store.commit("components/setGuestTypes", response.data.meta.guestType);
    }
  }
}
