<template>
  <v-app-bar id="title-bar" height="50" app>
    <!-- Display an additional icon -->
    <slot name="prefix" />

    <!-- Display the app title -->
    <v-app-bar-title>
      <slot name="default">
        <div style="display: inline-block; line-height: 30px">
          <img
            src="@/assets/img/reclink-glyph.png"
            alt="Reclink"
            height="30"
            style="vertical-align: center; float: left; margin-right: 5px"
          />

          Club Reclink
        </div>
      </slot>
    </v-app-bar-title>
    <v-spacer></v-spacer>

    <!-- Display an additional icon -->
    <slot name="postfix" />

    <!-- if theres an extension, display it -->
    <template v-if="$slots.hasOwnProperty('extension')" slot="extension">
      <slot name="extension" />
    </template>
    <!-- Display the navbar icon if we should show the nav -->
    <v-app-bar-nav-icon v-show="showNavIcon" @click="toggleNav" />
  </v-app-bar>
</template>

<script>
export default {
  name: "TitleBar",
  computed: {
    showNavIcon() {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  methods: {
    toggleNav() {
      this.$store.commit("components/toggleNav");
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/style/variables.scss";

header#title-bar {
  background-color: $color-panel-title;
  color: #ffffff;

  &::v-deep {
    .v-app-bar-title__content {
      font-size: 24px;
      overflow: visible;
      margin-top: -3px;
    }
    .theme--light.v-btn.v-btn--icon {
      color: rgba(255, 255, 255, 0.87);
    }
    .v-toolbar__extension {
      background-color: $color-panel-background;
      padding-left: 30px;
      .theme--light.v-tabs > .v-tabs-bar .v-tab--disabled,
      .theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) {
        font-size: 12px;
        color: #ffffff;
      }
      .v-tab--active {
        font-size: 12px;
        color: #ffffff;
      }
    }
  }
}
</style>
