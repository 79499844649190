import { AxiosResponse } from "axios";
import { isLooseObject } from "@/lib/utils";

export default class ApiResponseHelper {
  /**
   * Returns the error messages from the given response.
   *
   * @param response
   */
  static getErrorMessagesFromResponse(response: AxiosResponse): string[] {
    const messages: string[] = [];

    if (
      response &&
      typeof response === "object" &&
      Object.prototype.hasOwnProperty.call(response, "data")
    ) {
      const data = response.data || {};
      const errors: Record<string, unknown>[] = [];

      if (Array.isArray(data.errors) && data.errors.length > 0) {
        /*
         * Case data is:
         * data = {
         *    errors: [
         *      { message: "..." }
         *    ]
         * }
         */
        data.errors.forEach((error: unknown) => {
          if (isLooseObject(error)) {
            errors.push(error);
          }
        });
      } else if (data && isLooseObject(data)) {
        /*
         * Case data is:
         * data = {
         *    data: [
         *      { attributes: { message: "..." } }
         *    ]
         * }
         */
        if (Array.isArray(data.data) && data.data.length > 0) {
          data.data.forEach((error: unknown) => {
            if (isLooseObject(error) && isLooseObject(error.attributes)) {
              errors.push(error.attributes);
            }
          });
        }
      }

      if (0 < errors.length) {
        // If the error object is resolved and has the "message" property.
        errors.forEach((error) => {
          if ("string" === typeof error.message) {
            messages.push(error.message);
          }
        });
      }
    }

    return messages;
  }
}
